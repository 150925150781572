<template>
    <v-container v-if="$store.state.user">

        <!-- Page header -->
        <v-row class="text-center mt-10">
            <v-col class="mb-2">
                <h1 class="display-2 font-weight-bold mb-3">Associations - Edit</h1>
            </v-col>
        </v-row>

        <!-- Form start -->
        <v-row class="justify-center">
            <v-col class="mt-16" cols="4">
                <v-form ref="form" v-model="valid" lazy-validation>

                    <!-- Name text field -->
                    <v-text-field v-model="name" :rules="nameRules" label="Name" required></v-text-field>

                    <!-- Interest field -->
                    <v-text-field v-model="interest" :rules="interestRules" label="Interest" required></v-text-field>

                    <!-- Late Fee field -->
                    <v-text-field v-model="late_fee" :rules="lateFeeRules" label="Late Fee" required></v-text-field>                    

                    <!-- Notes text field -->
                    <v-textarea v-model="notes" label="Notes"></v-textarea>

                    <!-- Boolean Archived Selector -->
                    <v-checkbox class="justify-contents-right" v-model="checkbox" :label="`Archive`"></v-checkbox>

                    <!-- Form submit button -->
                    <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate(); archivePeople()">Submit</v-btn>

                    <!-- Form clear button (Remove user input) -->
                    <v-btn color="error" class="mr-4" @click="reset">Clear Form</v-btn>

                </v-form>
            </v-col> 
        </v-row>
    </v-container>
</template>

<script>
import AssociationsService from '../../services/AssociationsService'
import PeopleService from '../../services/PeopleService'
    export default {
        name: 'associationsEdit',

        data () {
            return {
                id: null,
                valid: true,
                name: '',
                interest: null,
                late_fee: null,
                notes: '',
                associationID: null,
                date: new Date().toISOString().slice(0, 19).replace('T', ' '),
                entry: null,
                checkbox: false,
                archived: this.checkbox,

                // Custom rules to ensure user input is valid
                nameRules: [
                    v => !!v || 'Name is required',
                ],
                interestRules: [
                    v => !!v || 'Interest is required',
                    // v => /[0-9]*\.[0-9]{2,2}$/.test(v) || 'Must be in the form of a two-digit decimal.',
                ],
                lateFeeRules: [
                    v => !!v || 'Late Fee is required',
                ],
            }
        },

        async mounted() {
            this.id = this.$store.state.route.params.association_id
            this.entry = (await AssociationsService.getAssociationByID({association_id: this.id})).data
            this.name = this.entry.name
            this.interest = this.entry.interest
            this.late_fee = this.entry.late_fee
            this.notes = this.entry.notes
            this.checkbox = this.entry.archived
        },

        methods: {
            // Checks user input against rules created above
            async validate () {
                this.$refs.form.validate()
                this.associations = await AssociationsService.editAssociation({
                    id: this.id,
                    name: this.name,
                    interest: parseFloat(this.interest),
                    late_fee: parseFloat(this.late_fee),
                    notes: this.notes,
                    archived: this.checkbox
                }).then(await new Promise(resolve => setTimeout(resolve, 1000))).then(this.$router.go(-1))
            },

            async archivePeople() {
                if (this.checkbox) { this.archivedPeople = await PeopleService.autoArchiveAll({params: {association_id: this.id}}) }
                else { this.archivedPeople = await PeopleService.autoUnarchiveAll({params: {association_id: this.id}}) }
            },

            // Clears user input within form
            reset () {
                this.$refs.form.reset()
            }
        }

    }
</script>